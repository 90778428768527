import back from './assets/back.png';
import './App.css';
import { useEffect, useState } from 'react';
function App() {
  // const allOpers = [
  //   'ash',
  //   'bandit',
  //   'blackbeard',
  //   'blitz',
  //   'buck',
  //   'capitao',
  //   'castle',
  //   'caveira',
  //   'doc',
  //   'echo',
  //   'frost',
  //   'fuze',
  //   'glaz',
  //   'hibana',
  //   'iq',
  //   'jackal',
  //   'jager',
  //   'kapkan',
  //   'lesion',
  //   'mira',
  //   'montagne',
  //   'mute',
  //   'pulse',
  //   'rook',
  //   'sledge',
  //   'smoke',
  //   'thatcher',
  //   'thermite',
  //   'twitch',
  //   'valkyrie',
  //   'ying',
  //   'tachanka',
  //   'alibi',
  //   'amaru',
  //   'clash',
  //   'dokkaebi',
  //   'ela',
  //   'finka',
  //   'goyo',
  //   'gridlock',
  //   'kaid',
  //   'kali',
  //   'lion',
  //   'maverick',
  //   'mozzie',
  //   'nokk',
  //   'nomad',
  //   'vigil',
  //   'wamai',
  //   'warden',
  //   'ace',
  //   'iana',
  //   'melusi',
  //   'oryx',
  //   'zero',
  //   'aruni',
  //   'azami',
  //   'brava',
  //   'deimos',
  //   'fenrir',
  //   'flores',
  //   'grim',
  //   'osa',
  //   'ram',
  //   'sens',
  //   'solis',
  //   'thorn',
  //   'thunderbird',
  //   'tubarao',
  //   'zofia',
  //   'maestro',
  // ];
  const victorOpenOpers = [
    'ash',
    'bandit',
    'blackbeard',
    'blitz',
    'buck',
    'capitao',
    'castle',
    'caveira',
    'doc',
    'echo',
    'frost',
    'fuze',
    'glaz',
    'hibana',
    'iq',
    'jackal',
    'jager',
    'kapkan',
    'lesion',
    'mira',
    'montagne',
    'mute',
    'pulse',
    'rook',
    'sledge',
    'smoke',
    'thatcher',
    'thermite',
    'twitch',
    'valkyrie',
    'ying',
    'tachanka',
    'alibi',
    'amaru',
    'clash',
    'dokkaebi',
    'ela',
    'finka',
    'goyo',
    'gridlock',
    'kaid',
    'kali',
    'lion',
    'maverick',
    'mozzie',
    'nokk',
    'nomad',
    'vigil',
    'wamai',
    'warden',
    'ace',
    'iana',
    'melusi',
    'oryx',
    'zero',
    'aruni',
    'azami',
    'deimos',
    'fenrir',
    'flores',
    'osa',
    'ram',
    'sens',
    'solis',
    'thorn',
    'thunderbird',
    'tubarao',
    'zofia',
    'maestro',
  ];
  const artemOpenOpers = [
    'ash',
    'bandit',
    'blackbeard',
    'blitz',
    'buck',
    'capitao',
    'castle',
    'doc',
    'echo',
    'frost',
    'fuze',
    'glaz',
    'hibana',
    'iq',
    'jackal',
    'jager',
    'kapkan',
    'lesion',
    'montagne',
    'mute',
    'pulse',
    'rook',
    'sledge',
    'smoke',
    'thatcher',
    'thermite',
    'twitch',
    'valkyrie',
    'ying',
    'tachanka',
    'alibi',
    'dokkaebi',
    'ela',
    'goyo',
    'gridlock',
    'kaid',
    'lion',
    'maverick',
    'mozzie',
    'nokk',
    'vigil',
    'wamai',
    'warden',
    'iana',
    'zero',
    'ram',
    'solis',
    'thorn',
    'zofia',
  ];
  const [oper, setOper] = useState({ artem: 'none', victor: 'none' });
  const [animated, setAnimated] = useState('');
  const getOper = () => {
    setOper({
      artem: artemOpenOpers[Math.floor(Math.random() * artemOpenOpers.length)],
      victor: victorOpenOpers[Math.floor(Math.random() * victorOpenOpers.length)],
    });
  };
  useEffect(() => {
    if (oper.artem !== 'none' && oper.victor !== 'none') {
      setAnimated('animating');

      const timeoutId = setTimeout(() => {
        setAnimated('');
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [oper]);

  return (
    <div className="App">
      <div style={{ backgroundImage: `url(${back})` }} className="App-back" />
      {oper.artem !== 'none' && (
        <div className="outputBlock">
          <img
            className="outputBlockBadge"
            alt={oper.artem.toUpperCase()}
            src={'./badges/' + oper.artem + '.png'}
          />
          <img
            className="outputBlockImage"
            alt={oper.artem.toUpperCase()}
            src={'./photos/' + oper.artem + '.png'}
          />
          <p className="outputBlockName">
            <span style={{ marginTop: '4px' }}>{oper.artem.toUpperCase()}</span>
          </p>
          <p className="outputBlockTitle">Geimerrrrr</p>
        </div>
      )}
      <div className="centerBlock">
        <div className="centerBlockLine" />
        <div className="centerBlockRound">
          {oper.artem === 'none' || oper.victor === 'none' ? (
            <svg width="60px" height="60px" viewBox="0 0 24 24" fill="none" onClick={getOper}>
              <path
                d="M22.5 12C22.5006 12.2547 22.4353 12.5051 22.3105 12.7271C22.1856 12.949 22.0055 13.1349 21.7875 13.2666L8.28 21.5297C8.05227 21.6691 7.79144 21.7453 7.52445 21.7502C7.25746 21.7552 6.99399 21.6887 6.76125 21.5578C6.53073 21.4289 6.3387 21.241 6.2049 21.0132C6.07111 20.7855 6.00039 20.5263 6 20.2622V3.73781C6.00039 3.47371 6.07111 3.21447 6.2049 2.98676C6.3387 2.75905 6.53073 2.57108 6.76125 2.44219C6.99399 2.31127 7.25746 2.24485 7.52445 2.24979C7.79144 2.25474 8.05227 2.33086 8.28 2.47032L21.7875 10.7334C22.0055 10.8651 22.1856 11.051 22.3105 11.2729C22.4353 11.4949 22.5006 11.7454 22.5 12Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="60px"
              height="60px"
              viewBox="0 0 24 24"
              fill="none"
              onClick={getOper}
              id="rel"
              className={animated}
            >
              <path
                d="M18.024 7.043C16.8528 5.87631 15.3626 5.08234 13.741 4.76116C12.1194 4.43997 10.439 4.60593 8.91152 5.23813C7.38407 5.87032 6.07791 6.94047 5.15761 8.31372C4.23732 9.68698 3.74406 11.3019 3.74 12.955"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M17.35 2.75L18.182 6.122C18.2203 6.26895 18.2284 6.42214 18.2059 6.57231C18.1833 6.72248 18.1305 6.86652 18.0507 6.9957C17.9708 7.12488 17.8656 7.23654 17.7414 7.3239C17.6172 7.41127 17.4766 7.47254 17.328 7.504L13.956 8.347"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.976 16.957C7.1471 18.124 8.63745 18.9183 10.2592 19.2397C11.881 19.5611 13.5616 19.3952 15.0893 18.7629C16.6169 18.1307 17.9233 17.0604 18.8436 15.6869C19.7639 14.3135 20.2571 12.6983 20.261 11.045"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
              />
              <path
                d="M6.65 21.25L5.818 17.878C5.77984 17.731 5.77187 17.5778 5.79457 17.4276C5.81727 17.2774 5.87018 17.1334 5.95008 17.0042C6.02999 16.8751 6.13525 16.7634 6.2595 16.6761C6.38375 16.5887 6.52441 16.5275 6.673 16.496L10.044 15.653"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
        <div className="centerBlockLine" />
      </div>
      {oper.victor !== 'none' && (
        <div className="outputBlock" style={{ bottom: 0 }}>
          <img
            className="outputBlockBadge"
            alt={oper.victor.toUpperCase()}
            src={'./badges/' + oper.victor + '.png'}
          />
          <img
            className="outputBlockImage"
            alt={oper.victor.toUpperCase()}
            src={'./photos/' + oper.victor + '.png'}
          />
          <p className="outputBlockName">
            <span style={{ marginTop: '4px' }}>{oper.victor.toUpperCase()}</span>
          </p>
          <p className="outputBlockTitle">Victorrrrr</p>
        </div>
      )}
    </div>
  );
}

export default App;

